/**
 * Header_menu
 * @constructor
 */
class pcHeaderMenu {
  constructor() {}
  /**
   * pcHeaderMenu
   */
  init() {
    var pc = window.matchMedia("(min-width: 751px)").matches;
    var sp = window.matchMedia("(max-width: 750px)").matches;
    if (pc) {
      $('.l-header__listItem--01,.l-header__menu--01').on({
        'mouseenter': function () {
          $('.l-header__menu--01').stop().fadeIn(200);
        },
        'mouseleave': function () {
          $('.l-header__menu--01').stop().fadeOut(200);
        }
      });
      $('.l-header__listItem--02,.l-header__menu--02').on({
        'mouseenter': function () {
          $('.l-header__menu--02').stop().fadeIn(200);
        },
        'mouseleave': function () {
          $('.l-header__menu--02').stop().fadeOut(200);
        }
      });
      $('.l-header__listItem--03,.l-header__menu--03').on({
        'mouseenter': function () {
          $('.l-header__menu--03').stop().fadeIn(200);
        },
        'mouseleave': function () {
          $('.l-header__menu--03').stop().fadeOut(200);
        }
      });
    }
  }
};

export default pcHeaderMenu;

/**
 * フローティングバナークラス
 * @constructor
 */

class FloatingBanner {
  /**
   * @param {string} this._targetClassName
   */
  constructor(targetClassName){
    this._targetClassName = targetClassName;
    this.init();
  }
  /**
   * フローティングバナー
   */
  init(){
    if(document.querySelector(this._targetClassName) != null){
      this.floatingBanner();
    }
  }
  floatingBanner(){
    const banner = document.querySelector(this._targetClassName);
    const footer = document.querySelector(".sitemap");
    banner.classList.add("is-show");

    //フッター付近でバナーを非表示
    window.addEventListener("scroll", function(){
      const footerPos = footer.getBoundingClientRect().top;

      if(footerPos < window.innerHeight){
        banner.classList.remove("is-show");
        banner.classList.add("is-hide");
      }else{
        banner.classList.remove("is-hide");
        banner.classList.add("is-show");
      }
    });
  }
}
export default FloatingBanner;
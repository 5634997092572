import Swiper from "swiper";
import Modaal from "modaal";

/**
 * 活動写真アーカイブ(/archive/)
 */
class archive {
  constructor() {}
  init() {

    /* recreation2020 */
    var swiper = new Swiper('.swiper-container-recreation2020', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-01',
        prevEl: '.swiper-button-prev-01',
      },
    });
    $('.gallery-recreation2020').modaal({
        type: 'image'
    });

    /* recreation2019-01 */
    var swiper = new Swiper('.swiper-container-recreation2019-01', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-01',
        prevEl: '.swiper-button-prev-01',
      },
    });
    $('.gallery-recreation2019-01').modaal({
        type: 'image'
    });

    /* recreation2019-02 */
    var swiper = new Swiper('.swiper-container-recreation2019-02', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-02',
        prevEl: '.swiper-button-prev-02',
      },
    });
    $('.gallery-recreation2019-02').modaal({
        type: 'image'
    });


    /* sports2019-01 */
    var swiper = new Swiper('.swiper-container-sports2019-01', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-sports2019-01',
        prevEl: '.swiper-button-prev-sports2019-01',
      },
    });
    $('.gallery-sports2019-01').modaal({
        type: 'image'
    });


    /* sports2019-02 */
    var swiper = new Swiper('.swiper-container-sports2019-02', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-sports2019-02',
        prevEl: '.swiper-button-prev-sports2019-02',
      },
    });
    $('.gallery-sports2019-02').modaal({
        type: 'image'
    });

    /* recreation2018 */
    var swiper = new Swiper('.swiper-container-recreation2018', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-01',
        prevEl: '.swiper-button-prev-01',
      },
    });
    $('.gallery-recreation2018').modaal({
        type: 'image'
    });

    /* sports2018-01 */
    var swiper = new Swiper('.swiper-container-sports2018-01', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-sports2018-01',
        prevEl: '.swiper-button-prev-sports2018-01',
      },
    });
    $('.gallery-sports2018-01').modaal({
        type: 'image'
    });

    /* sports2018-02 */
    var swiper = new Swiper('.swiper-container-sports2018-02', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-sports2018-02',
        prevEl: '.swiper-button-prev-sports2018-02',
      },
    });
    $('.gallery-sports2018-02').modaal({
        type: 'image'
    });

    /* sports2018-03 */
    var swiper = new Swiper('.swiper-container-sports2018-03', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-sports2018-03',
        prevEl: '.swiper-button-prev-sports2018-03',
      },
    });
    $('.gallery-sports2018-03').modaal({
        type: 'image'
    });

    /* sports2018-04 */
    var swiper = new Swiper('.swiper-container-sports2018-04', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-sports2018-04',
        prevEl: '.swiper-button-prev-sports2018-04',
      },
    });
    $('.gallery-sports2018-04').modaal({
        type: 'image'
    });

    /* sports2018-05 */
    var swiper = new Swiper('.swiper-container-sports2018-05', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-sports2018-05',
        prevEl: '.swiper-button-prev-sports2018-05',
      },
    });
    $('.gallery-sports2018-05').modaal({
        type: 'image'
    });

    /* sports2018-05 */
    var swiper = new Swiper('.swiper-container-sports2018-05', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-sports2018-05',
        prevEl: '.swiper-button-prev-sports2018-05',
      },
    });
    $('.gallery-sports2018-05').modaal({
        type: 'image'
    });

    /* sports2018-06 */
    var swiper = new Swiper('.swiper-container-sports2018-06', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-sports2018-06',
        prevEl: '.swiper-button-prev-sports2018-06',
      },
    });
    $('.gallery-sports2018-06').modaal({
        type: 'image'
    });

    /* activity2018-01 */
    var swiper = new Swiper('.swiper-container-activity2018-01', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-activity2018-01',
        prevEl: '.swiper-button-prev-activity2018-01',
      },
    });
    $('.gallery-activity2018-01').modaal({
        type: 'image'
    });

    /* activity2018-02 */
    var swiper = new Swiper('.swiper-container-activity2018-02', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-activity2018-02',
        prevEl: '.swiper-button-prev-activity2018-02',
      },
    });
    $('.gallery-activity2018-02').modaal({
        type: 'image'
    });

    /* recreation2017 */
    var swiper = new Swiper('.swiper-container-recreation2017', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-01',
        prevEl: '.swiper-button-prev-01',
      },
    });
    $('.gallery-recreation2017').modaal({
        type: 'image'
    });

    /* recreation2016 */
    var swiper = new Swiper('.swiper-container-recreation2016', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-2016',
        prevEl: '.swiper-button-prev-2016',
      },
    });
    $('.gallery-recreation2016').modaal({
        type: 'image'
    });

    /* recreation2015 */
    var swiper = new Swiper('.swiper-container-recreation2015', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-2015',
        prevEl: '.swiper-button-prev-2015',
      },
    });
    $('.gallery-recreation2015').modaal({
        type: 'image'
    });

    /* recreation2014 */
    var swiper = new Swiper('.swiper-container-recreation2014', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-2014',
        prevEl: '.swiper-button-prev-2014',
      },
    });
    $('.gallery-recreation2014').modaal({
        type: 'image'
    });

    /* sports2014 */
    var swiper = new Swiper('.swiper-container-sports2014', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-sports2014',
        prevEl: '.swiper-button-prev-sports2014',
      },
    });
    $('.gallery-sports2014').modaal({
        type: 'image'
    });

    /* recreation2013 */
    var swiper = new Swiper('.swiper-container-recreation2013', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-2013',
        prevEl: '.swiper-button-prev-2013',
      },
    });
    $('.gallery-recreation2013').modaal({
        type: 'image'
    });

    /* recreation2012 */
    var swiper = new Swiper('.swiper-container-recreation2012', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-2012',
        prevEl: '.swiper-button-prev-2012',
      },
    });
    $('.gallery-recreation2012').modaal({
        type: 'image'
    });

    /* recreation2011 */
    var swiper = new Swiper('.swiper-container-recreation2011', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-2011',
        prevEl: '.swiper-button-prev-2011',
      },
    });
    $('.gallery-recreation2011').modaal({
        type: 'image'
    });

    /* sports2010 */
    var swiper = new Swiper('.swiper-container-sports2010', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-sports2010',
        prevEl: '.swiper-button-prev-sports2010',
      },
    });
    $('.gallery-sports2010').modaal({
        type: 'image'
    });

    /* recreation2009 */
    var swiper = new Swiper('.swiper-container-recreation2009', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-2009',
        prevEl: '.swiper-button-prev-2009',
      },
    });
    $('.gallery-recreation2009').modaal({
        type: 'image'
    });

    /* recreation2008 */
    var swiper = new Swiper('.swiper-container-recreation2008', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-2008',
        prevEl: '.swiper-button-prev-2008',
      },
    });
    $('.gallery-recreation2008').modaal({
        type: 'image'
    });

    /* sports2008 */
    var swiper = new Swiper('.swiper-container-sports2008', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-sports2008',
        prevEl: '.swiper-button-prev-sports2008',
      },
    });
    $('.gallery-sports2008').modaal({
        type: 'image'
    });

    /* recreation2007 */
    var swiper = new Swiper('.swiper-container-recreation2007', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-2007',
        prevEl: '.swiper-button-prev-2007',
      },
    });
    $('.gallery-recreation2007').modaal({
        type: 'image'
    });

    /* sports2007 */
    var swiper = new Swiper('.swiper-container-sports2007', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-sports2007',
        prevEl: '.swiper-button-prev-sports2007',
      },
    });
    $('.gallery-sports2007').modaal({
        type: 'image'
    });
    
    /* sports2006 */
    var swiper = new Swiper('.swiper-container-sports2006', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-sports2006',
        prevEl: '.swiper-button-prev-sports2006',
      },
    });
    $('.gallery-sports2006').modaal({
        type: 'image'
    });
    
    /* sports2005 */
    var swiper = new Swiper('.swiper-container-sports2005', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-sports2005',
        prevEl: '.swiper-button-prev-sports2005',
      },
    });
    $('.gallery-sports2005').modaal({
        type: 'image'
    });

    /* recreation2004 */
    var swiper = new Swiper('.swiper-container-recreation2004', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next-2004',
        prevEl: '.swiper-button-prev-2004',
      },
    });
    $('.gallery-recreation2004').modaal({
        type: 'image'
    });

  }
}

export default archive;
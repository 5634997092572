/**
 * ヘッダーメニュー
 */
class headerMenu {
  constructor() {}
  init() {
    $('.l-spHeader__btn').on('click', function () {
      $(this).toggleClass('is-active');
      $('.l-spHeader__ttl').fadeToggle(300);
      $('.l-spHeader__menu').stop().fadeToggle(300);
    })
  }
}

export default headerMenu;

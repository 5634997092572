/**
 * コモンクラス
 * @constructor
 */
class Common {
  constructor() {}
  /**
   * common
   */
  init() {
    $('.js-headerSubMenu').on('mouseenter', (e) => {
      this.headerSubMenuOpen(e.currentTarget);
    })
    $('.js-headerSubMenu').on('mouseleave', (e) => {
      this.headerSubMenuClose(e.currentTarget);
    })
  }
  headerSubMenuOpen(el) {
    $(el).find('.l-headerInfo').addClass('is-active');
  }
  headerSubMenuClose(el) {
    $(el).find('.l-headerInfo').removeClass('is-active');
  }

}

export default Common;

import {TweenMax} from "gsap";
import {TimelineMax} from "gsap";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
const controller = new ScrollMagic.Controller;
/**
 * ページトップ
 */
class pagetop {
  constructor() {}
  init() {
    var topBtn = $('#pagetop');
    topBtn.click(function () {
      $('body,html').animate({
        scrollTop: 0
      }, 300);
      return false;
    });
    const pagetop = new TimelineMax();
    new ScrollMagic.Scene({
      triggerElement: '.sitemap',
      triggerHook: 'onEnter',
      duration: 0,
      reverse: true,
    })
    .setTween(pagetop)
    .setClassToggle('.pagetop', 'abso')
//    .addIndicators({
//      name: "pagetop"
//    })
    .addTo(controller);
  }
}
export default pagetop;

import Swiper from "swiper";
import Modaal from "modaal";

/**
 * 職場環境ページ（/recruit/qualification/）
 */
class quali {
  constructor() {}
  init() {
    /*講師紹介のアコーディオン*/
    $('.m-more--bottom').on('click', function () {
      $('.p-quali__intro--02').stop().slideDown(300);
      $(this).fadeOut(300);
    })

    /*資格取得までのカリキュラムのアコーディオン*/
    $('.p-quali__ccmTtl').on('click', function () {
      $(this).toggleClass('is-active');
      $(this).next().stop().slideToggle(300);
    })

    /*スライダー*/
    var swiper = new Swiper('.swiper-container-quali', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    /*スライダーのモーダル*/
    $('.gallery').modaal({
        type: 'image'
    });
  }
}



export default quali;

/**
 * 用語集ページ（/words/）
 */
class words {
  constructor() {}
  init() {
    /*用語集のアコーディオン*/
    $('.p-words__h3ttl').on('click', function () {
      $(this).toggleClass('is-active');
      $(this).next().stop().slideToggle(300);
    })
  }
}

export default words;

/**
 * 採用LP用
 * @constructor
 */
class recruitLp {
  constructor() {}
  /**
   * recruitLp
   */
  init() {
    var mainElement = document.getElementById('recruitLp');
    if(mainElement){
      var pagetopElement = document.getElementById('pagetop');
      var pagetopImage = pagetopElement.querySelector('img');

      if(pagetopImage){
        pagetopImage.src = getThemeImagePath();
      }
    }
    function getThemeImagePath() {
      // 現在のURLを取得
      const currentURL = window.location.href;
    
      // テーマのディレクトリまでのパスを設定
      const themePath = "/wp-content/themes/hokuto/";
    
      // 画像フォルダまでのパスを追加
      const imagePath = themePath + "assets/img/common/pagetop--recruit.svg";
    
      return imagePath;
    }
    
    const themeImagePath = getThemeImagePath();
  }  
}
export default recruitLp;

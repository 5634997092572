import Swiper from "swiper";
/**
 * トップページ
 */
class topSlider {
  constructor() {}
  init() {
    /*kvスライダー*/
    var swiper = new Swiper('.swiper-container-top', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
    
    var swiper = new Swiper('.swiper-container-top--social', {
      loop: true,
      speed: 500,
      autoplay: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
    
    /*IEでのTwitter制御*/
    const ua = navigator.userAgent;
    if (ua.indexOf('Trident') !== -1) {
      console.log('IE')
      $('.p-topTwitter__flexInner a').hide();
      $('.p-topTwitter__flexInner').append('<picture><source media="(max-width:750px)" srcset="/wp-content/themes/hokuto/assets/img/wakate/wakate_img02--sp.png"><img src="/wp-content/themes/hokuto/assets/img/wakate/wakate_img02.png" alt="公式Twitter"></picture>');
    }
  }
}

export default topSlider;

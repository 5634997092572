/**
 * カンパニー
 */
class company {
  constructor() {}
  init() {
    var url = location.href;
    if (document.URL.match('/company/result/')) {
      $('.urlChange01').attr('href', '#infrastructure')
      $('.urlChange02').attr('href', '#water')
      $('.urlChange03').attr('href', '#construction')
      $('.urlChange04').attr('href', '#office')
      $('.urlChange05').attr('href', '#design')
    }
  }
}

export default company;

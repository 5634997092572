/**
 * import Vue
 * ※vueを使用しない場合は削除する
 */
//import App from './vue/App.js';
/**
 * import node_modules
 */
import 'desvg';
import objectFitImages from 'object-fit-images';
import picturefill from 'picturefill';
import SmoothScroll from 'smooth-scroll';

/**
 * import modules
 */
import Common from './modules/common';
import Scroll from './modules/scroll';
import HeaderMenu from './modules/headerMenu';
import PcHeaderMenu from './modules/pcHeaderMenu';
import TopSlider from './modules/topSlider';
import Company from './modules/company';
import Wakate from './modules/wakate';
import Quali from './modules/quali';
import Welfare from './modules/welfare';
import Recreation from './modules/recreation';
import Sports from './modules/sports';
import Words from './modules/words';
import Archive from './modules/archive';
import Pagetop from './modules/pagetop';
import RecruitLp from './modules/recruitLp';
import FloatingBanner from './modules/floatingBanner';

const target = $('main').attr('id');
switch (target) {
  case 'top':
    console.log('top');
    const topSlider = new TopSlider();
    topSlider.init();
    const topfloating = new FloatingBanner('.js-floatingBanner');
    topfloating.init();
    break;

  case 'recruit': 
  const recruitfloating = new FloatingBanner('.js-floatingBanner');
  recruitfloating.init();

  case 'company-result':
    console.log('company-result');
    const company = new Company();
    company.init();
    break;

  case 'wakate':
    console.log('wakate');
    const wakate = new Wakate();
    wakate.init();
    break;

  case 'recruit-qualification':
    console.log('recruit-qualification');
    const quali = new Quali();
    quali.init();
    break;

  case 'recruit-welfare':
    console.log('recruit-welfare');
    const welfare = new Welfare();
    welfare.init();
    break;

  case 'recruit-recreation':
    console.log('recruit-recreation');
    const recreation = new Recreation();
    recreation.init();
    break;

  case 'recruit-sports':
    console.log('recruit-sports');
    const sports = new Sports();
    sports.init();
    break;

  case 'words':
    console.log('words');
    const words = new Words();
    words.init();
    break;

  case 'archive':
    console.log('archive');
    const archive = new Archive();
    archive.init();
    break;

  case 'recruitLp':
    const recruitLp = new RecruitLp();
    recruitLp.init();
    break;    
}

/**
 * Mainクラス
 * @constructor
 */
class Main {
  constructor() {
    this.setup();
  }
  /**
   * インスタンス生成時に実行する処理
   */
  setup() {
    document.addEventListener('DOMContentLoaded', () => {
      this.created();

    })
    window.addEventListener('load', () => {
      this.mounted();
    });
  }
  /**
   * ページ読み込み時に実行する処理
   */
  created() {
    picturefill();

    const common = new Common();
    common.init();

    const scroll = new Scroll();
    scroll.init();

    const pcHeaderMenu = new PcHeaderMenu();
    pcHeaderMenu.init();

    const headerMenu = new HeaderMenu();
    headerMenu.init();

    const pagetop = new Pagetop();
    pagetop.init();

  }
  /**
   * 画像読み込み後に実行する処理
   */
  mounted() {

    this.init_deSVG('.js-desvg');

    this.init_objectFitImages('.object-fit');

    this.init_smoothScroll();
  }

  /**
   * ライブラリ/deSVG
   * インラインsvgをsvgタグに変換
   * https://github.com/benhowdle89/deSVG
   * @param {Element} target ターゲットとなる要素
   */
  init_deSVG(target) {
    deSVG(target, true)
  }

  /**
   * ライブラリ/object-fit-images
   * css object-fit のポリフィル
   * https://github.com/fregante/object-fit-images
   * @param {Element} target ターゲットとなる要素
   */
  init_objectFitImages(target) {
    objectFitImages(target);
  }

  /**
   * ライブラリ/picturefill
   * html pictureタグのポリフィル
   * https://scottjehl.github.io/picturefill/
   */
  init_picturefill() {
    picturefill();
  }

  /**
   * ライブラリ/smooth-scroll
   * スムーススクロール
   * https://github.com/cferdinandi/smooth-scroll
   */
  init_smoothScroll() {
    /**
     * ターゲットとなる要素
     * @type {Element}
     */
    const targetElement = 'a[href*="#"]';

    /**
     * オプション
     * @type {object}
     */
    const option = {
      speed: 1000,
      easing: 'easeInOutCubic',
      speedAsDuration: true,
      //header: '.l-header' 固定ヘッダーの場合
    }

    new SmoothScroll(targetElement, option);
  }
}

export default new Main();

import Swiper from "swiper";
import Modaal from "modaal";

/**
 * 職場環境ページ（/recruit/qualification/）
 */
class quali {
  constructor() {}
  init() {

    /*スライダー*/
    var swiper = new Swiper('.swiper-container-welfare--01', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next--01',
        prevEl: '.swiper-button-prev--01',
      },
    });

    /*スライダー*/
    var swiper = new Swiper('.swiper-container-welfare--02', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next--02',
        prevEl: '.swiper-button-prev--02',
      },
    });

    /*スライダーのモーダル*/
    $('.gallery').modaal({
        type: 'image'
    });
  }
}

export default quali;

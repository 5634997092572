import Swiper from "swiper";
/**
 * 若手活躍委員会ページ（/wakate/）
 */
class wakate {
  constructor() {}
  init() {
    /*スライダー制御*/
    var swiper = new Swiper('.swiper-container-wakate', {
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: false,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    /*IEでのTwitter制御*/
    const ua = navigator.userAgent;
    if (ua.indexOf('Trident') !== -1) {
      console.log('IE')
      $('.p-wakate__twitterInner a').hide();
      $('.p-wakate__twitterInner').append('<picture><source media="(max-width:750px)" srcset="/wp-content/themes/hokuto/assets/img/wakate/wakate_img02--sp.png"><img src="/wp-content/themes/hokuto/assets/img/wakate/wakate_img02.png" alt="公式Twitter"></picture>');
    }
  }
}

export default wakate;
